import React from 'react'

import MainLayout from '../../layouts/mainlayout/MainLayout'
import PageHeader from '../../components/pageheader/PageHeader'

function GetConnected() {
	return (
		<MainLayout title="About Us | Get Connected">
			<PageHeader title="Get Connected" subnav="about" />
			Get Connected
		</MainLayout>
	)
}

export default GetConnected